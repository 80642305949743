<template>
  <div class="page">
    <van-card
      v-for="(item, index) in list"
      :key="index"
      :thumb="item.items[0].titlepic"
      :num="item.item_count"
      :price="item.order_total_text"
      currency=""
      @click-thumb="openOrderDetail(item.order_id)"
    >
      <template #title>
        <a href="javascript:;" @click="openOrderDetail(item.order_id)">
          <h3>订单编号: {{ item.order_no }}</h3>
        </a>
      </template>
      <template #desc>
        <div class="pv-10">
          <p>
            <van-tag class="mr-5">订单状态:</van-tag>
            <span v-html="item.is_paid_text" class="mr-10"></span>
            <span v-html="item.is_shipped_text"></span>
          </p>
          <p v-if="item.shipping_no">
            <van-tag type="primary" class="mr-5">发货单号:</van-tag>
            <span v-html="item.shipping_no"></span>
          </p>
        </div>
      </template>
      <template #footer>
        <van-button
          size="small"
          icon="delete"
          class="fl"
          @click="delOrder(item.order_id)"
        ></van-button>
        <van-button size="small" @click="openOrderDetail(item.order_id)"
          >查看订单</van-button
        >
      </template>
    </van-card>

    <div class="mv-10">
      <van-pagination
        v-model="page"
        :items-per-page="size"
        :total-items="total"
        mode="simple"
        @change="init"
      />
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { Pagination, Card, Dialog, Tag } from "vant";
Vue.use(Pagination).use(Card).use(Tag);

export default {
  name: "orderList",
  data: function () {
    return {
      list: [],
      total: 0,
      page: 1,
      size: 6,
    };
  },
  methods: {
    init() {
      const param = {
        page: this.page,
        size: this.size,
      };
      this.$http.get("user/orders", param).then((res) => {
        const { total, data } = res;
        this.total = total;
        this.list = data;
      });
    },
    delOrder(order_id) {
      Dialog.confirm({
        message: "删除本订单？",
      })
        .then(() => {
          this.$http.post("user/delete_order", { order_id }).then((res) => {
            if (res.code === 0) this.init();
          });
        })
        .catch(() => {
          // on cancel
        });
    },
    openOrderDetail(id) {
      this.$router.push({ name: "order", params: { id } });
    },
    payOrder(order) {
      this.$http
        .get("e/ShopSys/?do=ShopDdToPay", {
          ddid: order.ddid,
          payid: order.payfsid,
        })
        .then((res) => {
          if (res.code === 0) {
            log(res.data);
            return;
            window.location.href = res.data;
          }
        });
    },
  },
  activated() {
    this.init();
  },
};
</script>

<style></style>
